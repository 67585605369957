import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Links`}</em>{` are used primarily as a navigational element. Links may also change what or how data is displayed (i.e., view more, show all). If the action taken by the user will change or manipulate data, use a `}<a parentName="p" {...{
        "href": "/components/button"
      }}>{`button`}</a>{`.`}</p>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <ul>
      <li parentName="ul">{`Use text for links rather than graphics or icons.`}</li>
      <li parentName="ul">{`Links should be three words or less.`}</li>
      <li parentName="ul">{`Text should be consistent with the title of the intended destination.`}</li>
      <li parentName="ul">{`Use caution with links that are several words long. It is recommended that links are long enough to be understood by the user, but short enough to prevent text wrapping.`}</li>
      <li parentName="ul">{`Avoid the term “click here,” other links to “here,” or the web address itself. Instead, use a meaningful descriptive label for the link, and match the destination site name.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      